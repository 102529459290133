<template>
  <div
    class="user-card"
    :class="{
      'user-card--collapse': hideDetail,
      'user-card--patient': type == 'patient',
    }"
  >
    <div class="user-card__avatar" @click="$emit('click')">
      <div class="d-flex">
        <img
          v-if="type == 3 || type == 4 || type == 5"
          :src="
            profile.urlImage != null
              ? profile.urlImage
              : require('@/assets/profile_icon@2x.png')
          "
        />
        <img
          v-else
          :src="
            profile.urlImage != null
              ? profile.urlImage
              : require('@/assets/profile_icon@2x.png')
          "
        />
      </div>
      <div
        class="avatar__notif"
        :class="{ 'avatar__notif--active': notif !== '' }"
      >
        {{ notif }}
      </div>
    </div>
    <!-- <localized-link to="/patient/profile"> -->

    <div class="user-card__info" @click="$emit('click')">
      <div>
        <div class="user-card__name">
          {{ $fnc.replaceSpecialChars(profile.name) }}
        </div>
        <div
          class="user-card__email"
          v-if="type == 2 || type == 3 || type == 4 || type == 5"
        >
          {{ profile.email }}
        </div>
        <template v-if="type == 1">
          <div
            class="user-card__verified d-flex justify-start align-center"
            v-if="profile.verified == true"
          >
            <icons icon="icon-verified" color="#1f3c87" class="pr-1" />
            {{ $t("verified") }}
          </div>
          <div
            class="user-card__unverified d-flex justify-start align-center"
            v-if="profile.verified == false"
          >
            <icons icon="icon-verified" color="#D5D5D5" class="pr-1" />
            {{ $t("unverified") }}
          </div>
        </template>
      </div>
    </div>
    <!-- </localized-link> -->
  </div>
</template>

<script>
import icons from "@/components/elements/Icons.vue";

export default {
  components: {
    icons,
  },
  data: () => ({
    url: process.env.VUE_APP_API_TRUST,
  }),
  props: {
    hideDetail: {
      type: Boolean,
      default: false,
    },
    type: {
      type: [String, Number],
      default: "",
    },
    notif: {
      type: String,
      default: "",
    },
    profile: {
      type: Object,
      default: () => ({}),
    },
  },
  created() {},
  methods: {},
};
</script>

<style lang="scss" scoped>
@import "../../../assets/scss/color.scss";
.menu__icon {
  margin-right: 14px;
  display: flex;
  align-items: center;
  width: 20px;
}
.user-card {
  display: flex;
  align-items: center;
  width: 100%;
  padding: 26px;
  cursor: pointer;
  a {
    text-decoration: none;
  }
  &--collapse {
    .user-card {
      &__avatar {
        img {
          margin: 0;
        }
      }
      &__info {
        display: none;
      }
    }
  }
  // @media screen and (max-width: 960px) {
  //   padding: 12px;
  //   width: auto;
  //   .user-card {
  //     &__avatar {
  //       img {
  //         margin: 0;
  //       }
  //     }
  //     &__info {
  //       display: flex;
  //     }
  //   }
  // }

  @media screen and (max-width: 960px) {
    padding: 8px;
    .user-card__avatar {
      width: 40px;
      height: 40px;
    }
    &.user-card--patient {
      padding: 0;
    }
  }
  &__avatar {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    width: 50px;
    height: 50px;
    img {
      width: 100%;
      height: auto;
      border-radius: 50%;
      // margin-right: 16px;
    }
    .avatar__notif {
      position: absolute;
      display: none;
      height: 14px;
      width: 14px;
      background: $red_2;
      color: white;
      border-radius: 50%;
      right: 0;
      bottom: 0;
      font-style: normal;
      font-weight: 700;
      font-size: 6px;
      line-height: 6px;
      align-items: center;
      justify-content: center;
      &--active {
        display: flex;
      }
    }
  }
  &__info {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    height: 100%;
    padding-left: 5px;
  }
  &__name {
    color: $main_2;
    font-weight: 700;
    font-size: 12px;
    line-height: 20px;
    text-transform: capitalize;
  }
  &__email {
    color: $gray_3;
    font-style: normal;
    font-weight: 500;
    font-size: 11px;
    line-height: 16px;
    word-break: break-word;
  }
  &__verified {
    color: #1f3c87;
    font-style: normal;
    font-weight: 550;
    font-size: 12px;
    line-height: 12px;
    // word-break: break-word;
  }
  &__unverified {
    color: #eb7b7b;
    font-style: normal;
    font-weight: 550;
    font-size: 12px;
    line-height: 12px;
    // word-break: break-word;
  }
}

.width-60 {
  .user-card__avatar {
    width: 25px !important;
  }
}
</style>
